export type LocalazyLanguage = {
    language: string;
    region: string;
    script: string;
    isRtl: boolean;
    localizedName: string;
    name: string;
    pluralType: (n: number) => "zero" | "one" | "two" | "many" | "few" | "other";
};

export type LocalazyMetadata = {
    projectUrl: string;
    baseLocale: string;
    languages: LocalazyLanguage[];
};
     
const localazyMetadata: LocalazyMetadata = {
  projectUrl: "https://localazy.com/p/foxdeli-tt",
  baseLocale: "en",
  languages: [
    {
      language: "cs",
      region: "",
      script: "",
      isRtl: false,
      name: "Czech",
      localizedName: "Čeština",
      pluralType: (n) => { return (n===1) ? "one" : (n>=2 && n<=4) ? "few" : "other"; }
    },
    {
      language: "en",
      region: "",
      script: "",
      isRtl: false,
      name: "English",
      localizedName: "English",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "bg",
      region: "",
      script: "",
      isRtl: false,
      name: "Bulgarian",
      localizedName: "Български",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "de",
      region: "",
      script: "",
      isRtl: false,
      name: "German",
      localizedName: "Deutsch",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "el",
      region: "",
      script: "",
      isRtl: false,
      name: "Greek",
      localizedName: "Ελληνικά",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "es",
      region: "",
      script: "",
      isRtl: false,
      name: "Spanish",
      localizedName: "Español",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "fr",
      region: "",
      script: "",
      isRtl: false,
      name: "French",
      localizedName: "Français",
      pluralType: (n) => { return (n===0 || n===1) ? "one" : "other"; }
    },
    {
      language: "hr",
      region: "",
      script: "",
      isRtl: false,
      name: "Croatian",
      localizedName: "Hrvatski",
      pluralType: (n) => { return ((n%10===1) && (n%100!==11)) ? "one" : ((n%10>=2 && n%10<=4) && ((n%100<12 || n%100>14))) ? "few" : "other"; }
    },
    {
      language: "hu",
      region: "",
      script: "",
      isRtl: false,
      name: "Hungarian",
      localizedName: "Magyar",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "it",
      region: "",
      script: "",
      isRtl: false,
      name: "Italian",
      localizedName: "Italiano",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "nl",
      region: "",
      script: "",
      isRtl: false,
      name: "Dutch",
      localizedName: "Nederlands",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "pl",
      region: "",
      script: "",
      isRtl: false,
      name: "Polish",
      localizedName: "Polski",
      pluralType: (n) => { return (n===1) ? "one" : ((n%10>=2 && n%10<=4) && ((n%100<12 || n%100>14))) ? "few" : "many"; }
    },
    {
      language: "ro",
      region: "",
      script: "",
      isRtl: false,
      name: "Romanian",
      localizedName: "Română",
      pluralType: (n) => { return (n===1) ? "one" : (n===0 || n%100>=2 && n%100<=19) ? "few" : "other"; }
    },
    {
      language: "sk",
      region: "",
      script: "",
      isRtl: false,
      name: "Slovak",
      localizedName: "Slovenčina",
      pluralType: (n) => { return (n===1) ? "one" : (n>=2 && n<=4) ? "few" : "other"; }
    },
    {
      language: "sl",
      region: "",
      script: "",
      isRtl: false,
      name: "Slovenian",
      localizedName: "Slovenščina",
      pluralType: (n) => { return (n%100===1) ? "one" : (n%100===2) ? "two" : (n%100>=3 && n%100<=4) ? "few" : "other"; }
    }
  ]
};

export default localazyMetadata;