import { Notifier } from '@airbrake/browser';

import { AIRBRAKE_ENVIRONMENT, AIRBRAKE_PROJECT_ID, AIRBRAKE_PROJECT_KEY } from '~/lib/constants';

export interface CaptureErrorOptions {
  error: any,
  params?: {
    info?: any;
  },
  session?: {
    deliveryId?: string;
    slug?: string;
  }
}

class Logger {
  private readonly notifier?: Notifier;

  constructor() {
    if (
      typeof window !== 'undefined'
      && AIRBRAKE_ENVIRONMENT
      && AIRBRAKE_PROJECT_ID
      && AIRBRAKE_PROJECT_KEY
    ) {
      this.notifier = new Notifier({
        projectId: parseInt(AIRBRAKE_PROJECT_ID, 10),
        projectKey: AIRBRAKE_PROJECT_KEY,
        environment: AIRBRAKE_ENVIRONMENT,
      });
    }
  }

  captureError(options: CaptureErrorOptions) {
    if (this.notifier) {
      this.notifier.notify(options);
    }
  }
}

export const logger = new Logger();
