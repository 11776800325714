/**
 * Returns the specified query parameter as `string` or `undefined`. If the input is an array, will return the first
 * element of that array.
 *
 * Query parameters in Next can be `string | string[] | undefined`, but `string[]` is rarely used.
 */
export function parseQueryParam(param?: string | string[]): string | undefined {
  if (Array.isArray(param)) {
    return param[0];
  }

  return param;
}

/**
 * Filters null values from list of nullable values. Converts `Array<T | null>` to `Array<T>`.
 */
export function filterNull<T>(items?: Nullable<T>[] | null): T[] {
  if (items === undefined || items === null) {
    return [];
  }

  return items.filter((i) => i !== null) as T[];
}

/**
 * Merges multiple CSS class names into a single string.
 * Ignores null, undefined and false values so you can use conditional
 * class names such as:
 * - (condition && 'className')
 * - (condition ? 'className1' : className2)
 *
 * @param cssClasses
 * @return string
 */
export const cssMerge = (...cssClasses: (string | false | null | undefined)[]): string => {
  return cssClasses.filter((cssClass) => typeof cssClass === 'string').join(' ');
};
